import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
      <br/>
        <h1 className="definition"><b>Trump</b>:</h1>
        <h2 className="definition">1. The pungent mixture of sweat and urine that is sometimes the by-product of a <a href='https://www.urbandictionary.com/define.php?term=golden%20showers'>golden shower</a> performed on a heavily perspirating man.</h2>
        <h2 className="definition">2. President Donald J. Trump.</h2>
        <br/>
        <div className="">
          <img className="embed-responsive-item container" src="./trump.jpg" alt="trump sweating"></img>
        </div>
        <br/>
      </div>
    </div>
  );
}

export default App;
